<template>
  <div class="container">
    <div class="box">
      <!-- <div class="left-btn" v-show="!timer" @click.stop="handleNext">
        <i class="el-icon-arrow-left"></i>
      </div> -->
      <div
        class="box-content"
        ref="boxContent"
        @mouseenter="handleStop"
        @mouseleave="handleStart"
      >
        <!-- 单一行内容开始 -->
        <div class="box-img-content">
          <!-- 逆向显示的内容开始 -->
          <div class="box-img-content">
            <div
              class="box-content-img"
              v-for="(item, index) in imgLists1"
              :key="index"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
          <!-- 逆向显示的内容结束 -->
          <!-- 完整显示的内容开始 -->
          <div class="box-img-content">
            <div
              class="box-content-img"
              v-for="(item, index) in imgLists1"
              :key="index"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
          <!-- 完整显示的内容结束 -->
          <!-- 正向显示的内容开始 -->
          <div class="box-img-content">
            <div
              class="box-content-img"
              v-for="(item, index) in imgLists1"
              :key="index"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
          <!-- 正向显示的内容结束 -->
        </div>
        <!-- 单一行内容结束 -->
        <!-- 单一行内容开始 -->
        <div class="box-img-content">
          <!-- 逆向显示的内容开始 -->
          <div class="box-img-content">
            <div
              class="box-content-img"
              v-for="(item, index) in imgLists2"
              :key="index"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
          <!-- 逆向显示的内容结束 -->
          <!-- 完整显示的内容开始 -->
          <div class="box-img-content">
            <div
              class="box-content-img"
              v-for="(item, index) in imgLists2"
              :key="index"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
          <!-- 完整显示的内容结束 -->
          <!-- 正向显示的内容开始 -->
          <div class="box-img-content">
            <div
              class="box-content-img"
              v-for="(item, index) in imgLists2"
              :key="index"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
          <!-- 正向显示的内容结束 -->
        </div>
        <!-- 单一行内容结束 -->
      </div>
      <!-- <div class="right-btn" v-show="!timer" @click.stop="handlePrve">
        <i class="el-icon-arrow-right"></i>
      </div> -->
      <div class="box-content-img">
        <img :src="imgLists[0].img" alt="" />
      </div>
      <div class="box-content-img">
        <img :src="imgLists[0].img" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      imgLists1: [], //第一组图片数据
      imgLists2: [], //第二组图片数据
      imgLists: [
        { img: require("../assets/banner/undertakingCases1.jpg") },
        { img: require("../assets/banner/undertakingCases2.jpg") },
        { img: require("../assets/banner/undertakingCases3.jpg") },
        { img: require("../assets/banner/undertakingCases4.jpg") },
        { img: require("../assets/banner/undertakingCases5.jpg") },
        { img: require("../assets/banner/undertakingCases6.jpg") },
        { img: require("../assets/banner/undertakingCases7.jpg") },
        { img: require("../assets/banner/undertakingCases8.jpg") },
        { img: require("../assets/banner/undertakingCases9.jpg") },
        { img: require("../assets/banner/undertakingCases10.jpg") },
        { img: require("../assets/banner/undertakingCases11.jpg") },
        { img: require("../assets/banner/undertakingCases12.jpg") },
        { img: require("../assets/banner/undertakingCases13.jpg") },
        { img: require("../assets/banner/undertakingCases14.jpg") },
        { img: require("../assets/banner/undertakingCases15.jpg") },
        { img: require("../assets/banner/undertakingCases16.jpg") },
        { img: require("../assets/banner/undertakingCases17.jpg") },
        { img: require("../assets/banner/undertakingCases18.jpg") },
        { img: require("../assets/banner/undertakingCases19.jpg") },
        { img: require("../assets/banner/undertakingCases20.jpg") },
        { img: require("../assets/banner/undertakingCases21.jpg") },
        { img: require("../assets/banner/undertakingCases22.jpg") },
        { img: require("../assets/banner/undertakingCases23.jpg") },
        { img: require("../assets/banner/undertakingCases24.jpg") },
        { img: require("../assets/banner/undertakingCases25.jpg") },
        { img: require("../assets/banner/undertakingCases26.jpg") },
        { img: require("../assets/banner/undertakingCases27.png") },
        { img: require("../assets/banner/undertakingCases28.jpg") },
      ],
      contentSite: 0, //滚动层初始位置
      timer: null, //定时器
    };
  },

  mounted() {
    let arr1 = [];
    let arr2 = [];
    this.imgLists.forEach((item, index) => {
      if (index % 2 == 0) {
        arr1.push(item);
      } else {
        arr2.push(item);
      }
    });
    this.imgLists1 = arr1;
    this.imgLists2 = arr2;
    this.contentSite = arr1.length * 240;
    this.$nextTick(() => {
      this.$refs.boxContent.style.marginLeft = "-" + this.contentSite + "px";
      this.handleNext();
    });
  },
  methods: {
    // 正向事件
    handleNext() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.contentSite += 1;
          if (this.contentSite >= this.imgLists1.length * 240 * 2) {
            this.contentSite = this.imgLists1.length * 240;
          }
          this.$refs.boxContent.style.marginLeft =
            "-" + this.contentSite + "px";
        }, 30);
      }
    },
    // 逆向事件
    handlePrve() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.contentSite -= 1;
          if (this.contentSite <= this.imgLists1.length * 240 - 240 * 5) {
            this.contentSite = this.imgLists1.length * 240 * 2 - 240 * 5;
          }
          this.$refs.boxContent.style.marginLeft =
            "-" + this.contentSite + "px";
        }, 30);
      }
    },
    // 停止事件
    handleStop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    // 开始事件
    handleStart() {
      if (!this.timer) {
        this.handleNext();
      }
    },
  },
  computed: {
    ...mapState(["language"]),
  },
  destroyed() {
    this.handleStop();
  },
};
</script>

<style scoped>
.box {
  overflow: hidden;
  position: relative;
}

.box-content {
  position: absolute;
  top: 0;
  left: 0;
}

.box-img-content {
  display: flex;
}

.box-content-img {
  width: 240px;
  display: flex;
}

.box-content-img img {
  width: 100%;
}

.left-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eeeeee;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: 48%;
  left: 20px;
  z-index: 5;
}

.right-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eeeeee;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: 48%;
  right: 20px;
  z-index: 5;
}
</style>
